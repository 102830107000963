.jumbotron {
    height: 60%;
    min-height: 500px;
    position: relative;
    flex: 1;
}

.jumbotron > .background {
    background-image: url("../../res/img/home_jumbo.jpg");
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(1.5px) grayscale(.2);
}

.container {
    max-width: 1000px;
    margin: auto;
    padding: 30px 40px;
    /* background-color: aquamarine; */
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner-content {
    position: absolute;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 700px;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
}

.banner-content::after {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    top: -7px;
    left: -7px;
}

.banner-title {
    font-size: 2em;
    font-weight: 300;
    letter-spacing: 0.2em;
}

.banner-subtitle {
    font-size: 1.3em;
    letter-spacing: 0.1em;
}

.intro {
    letter-spacing: 2px;
    line-height: 1.8;
    font-weight: 450;
}

.resume-button {
    display: inline-block;
    width: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255);
    padding: 10px;
    border: black solid 5px;
    font-size: large;
    letter-spacing: 5px;
    font-weight: 500;
    margin-bottom: 1%;
}

.resume-button:hover {
    color: white;
    background-color: black;
}