@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:100);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  box-sizing: border-box;
  /* transition: 100ms; */
}

ul {
    padding: 0;
}

.Navbar_navList__1GFvw {
    display: flex;
    justify-content: center;
    height: 70px;
    list-style-type: none;
    margin: 0;
}

.Navbar_navList__1GFvw li {
    padding: 0 2%;
    height: 100%;
    display: flex;
    color: black;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Navbar_tab__2SHE7:hover {
    background-color: black;
    color: white;
}

.Navbar_navList__1GFvw a {
    text-decoration: none;
    margin: auto;
}

.Navbar_navList__1GFvw a:hover {
    color: white;
}

.Navbar_logo__1H3m4 {
    margin: 10px 0;
    height: calc(100% - 20px);
}

.Navbar_misc__3rln- {
    position: fixed;
    right: 0;
}

.Navbar_yuan__2GMCP {
    width: 70px;
    height: 70px;
}
.Home_jumbotron__2QghX {
    height: 60%;
    min-height: 500px;
    position: relative;
    flex: 1 1;
}

.Home_jumbotron__2QghX > .Home_background__YbFXU {
    background-image: url(/static/media/home_jumbo.4e02f88b.jpg);
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: blur(1.5px) grayscale(.2);
            filter: blur(1.5px) grayscale(.2);
}

.Home_container__1ukFy {
    max-width: 1000px;
    margin: auto;
    padding: 30px 40px;
    /* background-color: aquamarine; */
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home_banner-content__TuQ_v {
    position: absolute;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 700px;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
}

.Home_banner-content__TuQ_v::after {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    top: -7px;
    left: -7px;
}

.Home_banner-title__3x9_H {
    font-size: 2em;
    font-weight: 300;
    letter-spacing: 0.2em;
}

.Home_banner-subtitle__2Zwvl {
    font-size: 1.3em;
    letter-spacing: 0.1em;
}

.Home_intro__2WANc {
    letter-spacing: 2px;
    line-height: 1.8;
    font-weight: 450;
}

.Home_resume-button__3UeNd {
    display: inline-block;
    width: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255);
    padding: 10px;
    border: black solid 5px;
    font-size: large;
    letter-spacing: 5px;
    font-weight: 500;
    margin-bottom: 1%;
}

.Home_resume-button__3UeNd:hover {
    color: white;
    background-color: black;
}
/* html, body{
    font-family: 'Roboto Mono', monospace;
    background: #212121;
    height: 100%;
} */
.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #212121;
  flex: 1 1;
  flex-direction: column;
}
.text {
  margin-top: 2em;
  font-weight: 500;
  font-size: 28px;
  color: #fafafa;
  z-index: 1;
}
.dud {
  color: #757575;
}
.mirror {
  /* position: absolute; */
  height: 50vh;
}

.Footer_footer__1w0lV {
    background-color: black;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 1.5em 0;
};

.Footer_p__3EWTa {
    display: inline-block;
}

.Footer_icons__3k_Xg {
    margin: 0 auto;
}

.Footer_icon-wrapper__s9__- {
    display: inline-block;
    margin: 0 0.5em;
}

.Footer_icon__ISVxC {
    margin: 0 1em;
}
.Main_main-container__1sdix {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Main_content__2saOl {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.Main_welcome__2asS4 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: white;
}
