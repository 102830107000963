.footer {
    background-color: black;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 1.5em 0;
};

.p {
    display: inline-block;
}

.icons {
    margin: 0 auto;
}

.icon-wrapper {
    display: inline-block;
    margin: 0 0.5em;
}

.icon {
    margin: 0 1em;
}