ul {
    padding: 0;
}

.navList {
    display: flex;
    justify-content: center;
    height: 70px;
    list-style-type: none;
    margin: 0;
}

.navList li {
    padding: 0 2%;
    height: 100%;
    display: flex;
    color: black;
    align-items: center;
    width: fit-content;
}

.tab:hover {
    background-color: black;
    color: white;
}

.navList a {
    text-decoration: none;
    margin: auto;
}

.navList a:hover {
    color: white;
}

.logo {
    margin: 10px 0;
    height: calc(100% - 20px);
}

.misc {
    position: fixed;
    right: 0;
}

.yuan {
    width: 70px;
    height: 70px;
}