.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.welcome {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: white;
}