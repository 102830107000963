@import "https://fonts.googleapis.com/css?family=Roboto+Mono:100";
/* html, body{
    font-family: 'Roboto Mono', monospace;
    background: #212121;
    height: 100%;
} */
.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #212121;
  flex: 1;
  flex-direction: column;
}
.text {
  margin-top: 2em;
  font-weight: 500;
  font-size: 28px;
  color: #fafafa;
  z-index: 1;
}
.dud {
  color: #757575;
}
.mirror {
  /* position: absolute; */
  height: 50vh;
}
